import React, { useEffect, useRef } from 'react';

export default ({ videoId, videoID, autoPlay, type, loop }) => {
	const playerRef = useRef();

	useEffect(() => {
		const Plyr = require('plyr');
		const player = new Plyr(playerRef.current, {
			autoplay: autoPlay,
			muted: loop,
			hideControls: loop,
			clickToPlay: true,
			loop: {
				active: false,
			},
			playsinline: true,
		});
	}, [playerRef]);

	return (
		<section className="video-player mt5 mb5 mt10--md mb10--md mt20--lg mb20--lg">
			<div className="plyr__video-embed" ref={playerRef}>
				{type === 'youtube' ? (
					<iframe
						src={`https://www.youtube.com/embed/${
							videoId || videoID
						}?origin=https://eugeniedetroit.com&amp;iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1`}
						allowFullScreen
						allowtransparency="true"
						allow="autoplay"
					></iframe>
				) : (
					<iframe
						src={`https://player.vimeo.com/video/${
							videoId || videoID
						}?loop=false&amp;byline=false&amp;portrait=false&amp;title=false&amp;speed=true&amp;transparent=0&amp;gesture=media`}
						allowFullScreen
						allowtransparency="true"
						allow="autoplay"
					></iframe>
				)}
			</div>
		</section>
	);
};
